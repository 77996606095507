<template>
  <div :class="selector" class="message-modal">
    <img :src="require('../assets/img/svg/logo-text.svg')"
         class="lazyload message-modal__logo" alt="solo">
    <h3 v-if="title" class="message-modal__title">{{ title }}</h3>
    <div v-if="message" class="message-modal__text text">
      {{ message }}
    </div>
    <button @click.prevent="closeModal" class="message-modal__btn base-btn">
      {{ btnText || 'Закрити' }}
    </button>
  </div>
</template>

<script>
  import { SUCCESS, ERROR, WARNING } from "@/constants/messagesTypes";

  export default {
    props: {
      type: {
        type: String,
        default: SUCCESS,
      },
      title: String,
      message: String,
      btnText: String,
      callBack: {
        type: Function,
      },
    },
    data() {
      return {};
    },
    computed: {
      selector() {
        return {
          _success: this.type === SUCCESS,
          _warning: this.type === WARNING,
          _error: this.type === ERROR,
        };
      },
    },
    methods: {
      closeModal() {
        this.$vModal.close("message");
        if (typeof this.callBack === "function") {
          this.callBack();
        }
      },
    },
  };
</script>

<style lang="less">
</style>
